import { render, staticRenderFns } from "./PlacesOfUsageGroup.vue?vue&type=template&id=01fcbcd3&scoped=true&"
import script from "./PlacesOfUsageGroup.vue?vue&type=script&lang=js&"
export * from "./PlacesOfUsageGroup.vue?vue&type=script&lang=js&"
import style0 from "./PlacesOfUsageGroup.vue?vue&type=style&index=0&id=01fcbcd3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fcbcd3",
  null
  
)

export default component.exports