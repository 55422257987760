<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="$t(getModalTitle())"
        tile
        color="green"
        full-width
      >
        <v-form
          ref="form"
        >
          <v-flex
            xs12
            sm12
            md12
          >
            <v-text-field
              :rules="[ruleRequiredValue]"
              v-model="groupSiteData.name"
              :label="$t('common.groupName')"
              :name="$t('common.groupName')"
              type="text"
            />
          </v-flex>
        </v-form>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data () {
    return {
      isShowModal: false,
      groupSiteData: {
        id: null,
        name: null
      }
    }
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    onShowModal: function (groupSiteData) {
      this.isShowModal = true
      if (!functionUtils.isNull(groupSiteData)) {
        this.groupSiteData.name = groupSiteData.name
        this.groupSiteData.id = groupSiteData.id
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefaultData()
    },
    setDefaultData: function () {
      this.groupSiteData.id = null
      this.groupSiteData.name = null
    },
    getModalTitle: function () {
      if (!functionUtils.isNull(this.groupSiteData.id)) {
        return 'groupSites.updateRegion'
      } else {
        return 'groupSites.addRegion'
      }
    },
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let data = {
          id: this.groupSiteData.id,
          name: this.groupSiteData.name
        }
        if (functionUtils.isNull(this.groupSiteData.id)) {
          this.POST_SITE_GROUP(data).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
              this.$emit('callbackPlacesOfUsageGroupList')
              this.onCloseModal()
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  'styleType': ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        } else {
          this.UPDATE_SITE_GROUP(data).then(
            function () {
              this.ON_SHOW_TOAST({
                'message': this.$t('common.success'),
                'styleType': ToastType.SUCCESS
              })
              this.$emit('callbackPlacesOfUsageGroupList')
              this.onCloseModal()
            }.bind(this)
          ).catch(
            function (error) {
              if (!functionUtils.isNull(error.response.data.errorText)) {
                let errorText = error.response.data.errorText
                this.ON_SHOW_TOAST({
                  'message': this.$t(`${errorText}`),
                  'styleType': ToastType.ERROR
                })
              } else {
                this.ON_SHOW_TOAST({
                  'message': this.$t('login.somethingWentWrong'),
                  'styleType': ToastType.ERROR
                })
              }
            }.bind(this)
          )
        }
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'POST_SITE_GROUP',
      'UPDATE_SITE_GROUP'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
