<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="$t(getModalTitle())"
        tile
        color="green"
        full-width
      >
        <v-form
          ref="form">
          <v-flex
            xs12
            sm12
            md12
          >
            <v-autocomplete
              :rules="[ruleRequiredValue]"
              v-model="siteId"
              :items="siteList"
              :clearable="true"
              :label="$t('siteManagement.chooseSite')"
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <span>{{ $t(item.text) }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-flex>
        </v-form>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import EntityType from 'enum/entityType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
export default {
  data () {
    return {
      isShowModal: false,
      groupSiteData: {
        id: null,
        name: null
      },
      siteList: [],
      siteId: null
    }
  },
  computed: {
    ...mapGetters([
      'CHILDREN_ENTITY_DATA'
    ])
  },
  watch: {
    CHILDREN_ENTITY_DATA () {
      let data = this.CHILDREN_ENTITY_DATA
      this.siteList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let siteObj = {
          text: data[i].name,
          value: data[i].id,
          // for preview purpose
          name: data[i].name,
          email: data[i].email,
          phone: data[i].phone,
          province: data[i].province,
          district: data[i].district,
          ward: data[i].ward,
          street: data[i].street
        }
        this.siteList.push(siteObj)
      }
    }
  },
  created () {
    this.getChildrenEntity()
  },
  methods: {
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    onShowModal: function (groupSiteData) {
      this.isShowModal = true
      if (!functionUtils.isNull(groupSiteData)) {
        this.groupSiteData.name = groupSiteData.name
        this.groupSiteData.id = groupSiteData.id
      }
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefaultData()
    },
    setDefaultData: function () {
      this.groupSiteData.id = null
      this.groupSiteData.name = null
      this.siteId = null
    },
    /**
     * Get children entity
     */
    getChildrenEntity: function () {
      let filter = {
        params: {
          type: EntityType.SITE
        }
      }
      this.GET_CHILDREN_ENTITY(filter)
    },
    getModalTitle: function () {
      return 'groupSites.addSiteIntoRegion'
    },
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let data = {
          siteId: this.siteId,
          groupSiteId: this.groupSiteData.id
        }
        this.POST_SITE_GROUP_MAPPING(data).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': ToastType.SUCCESS
            })
            this.$emit('callbackPlacesOfUsageGroupMappingList')
            this.onCloseModal()
          }.bind(this)
        ).catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                'message': this.$t(`${errorText}`),
                'styleType': ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                'message': this.$t('login.somethingWentWrong'),
                'styleType': ToastType.ERROR
              })
            }
          }.bind(this)
        )
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'POST_SITE_GROUP_MAPPING',
      'GET_CHILDREN_ENTITY'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
