var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('groupSites.title'),"color":"green","flat":"","full-width":""}},[[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.onShowModalAddPlacesOfUsageGroup()}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],_c('div',{on:{"mouseleave":function($event){_vm.$refs.tblEntityList.expanded=[]}}},[_c('v-data-table',{ref:"tblEntityList",attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.entityHeaders,"items":_vm.groupSiteList,"single-select":true,"expand":false,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(props){return [_c('tr',_vm._l((_vm.entityHeaders),function(header,index){return _c('td',{key:header.value + index,class:[header.align == 'center' ? 'text-xs-center' : '']},[(header.value == 'actions')?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalUpdatePlacesOfUsageGroup(props.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('groupSites.updateRegion')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"info"},on:{"click":function($event){return _vm.onShowModalPlacesOfUsageGroupMapping(props.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-box-multiple")])],1)]}}],null,true)}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.onShowModalDelete(props.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1):_c('span',[_vm._v(_vm._s(props.item[header.value]))])])}),0)]}}])}),_c('pagination',{attrs:{"total":_vm.paginateData.totalPage,"current-page":_vm.paginateData.currentPage,"row-per-page":_vm.paginateData.rowPerPage},on:{"onPageChange":_vm.onPageChange}})],1)],2)],1)],1),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onConfirmDelete}}),_c('placesOfUsageGroupModal',{ref:"placesOfUsageGroupModal",on:{"callbackPlacesOfUsageGroupList":_vm.callbackPlacesOfUsageGroupList}}),_c('placesOfUsageGroupMapping',{ref:"placesOfUsageGroupMapping"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }