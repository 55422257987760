<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="1200px">
    <v-card>
      <material-card
        :title="$t('groupSites.title') + ` ${groupSiteData.name}`"
        tile
        color="green"
        full-width
      >
        <template>
          <v-btn
            color="success"
            dark
            style="float: right;"
            @click="onShowModalAddPlacesOfUsageGroupMapping()"
          >
            {{ $t('common.add') }}
          </v-btn>
        </template>
        <div @mouseleave="$refs.tblEntityList.expanded=[]">
          <v-data-table
            ref="tblEntityList"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="entityHeaders"
            :items="groupSiteList"
            :single-select="true"
            :expand="false"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template v-slot:items="props">
              <tr>
                <td
                  v-for="(header, index) in entityHeaders"
                  :key="header.value + index"
                  :class="[header.align == 'center' ? 'text-xs-center' : '']"
                >
                  <div v-if="header.value == 'actions'">
                    <!-- <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="primary"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalUpdatePlacesOfUsageGroup(props.item)"
                        >
                          <v-icon dark>mdi-pencil-box-outline</v-icon>
                        </v-btn>
                      </template>
                      <span >{{ $t('groupSites.updateRegion') }}</span>
                    </v-tooltip> -->
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mx-2"
                          fab
                          small
                          color="error"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalDelete(props.item)"
                        >
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span >{{ $t('common.delete') }}</span>
                    </v-tooltip>
                  </div>
                  <span v-if="header.value == 'address'">{{ props.item[header.value].fullAddress }}</span>
                  <span v-else>{{ props.item[header.value] }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
          <pagination
            :total="paginateData.totalPage"
            :current-page="paginateData.currentPage"
            :row-per-page="paginateData.rowPerPage"
            @onPageChange="onPageChange"
          />
        </div>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmDelete"
    />
    <placesOfUsageGroupMappingModal
      ref="placesOfUsageGroupMappingModal"
      @callbackPlacesOfUsageGroupMappingList="callbackPlacesOfUsageGroupMappingList"
    />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
import SysRoleType from 'enum/sysRoleType'
import ConfirmModal from 'Components/ConfirmModal'
import { FOR_CONASI_TARGET } from 'utils/constants'
import TabHeader from 'Components/TabHeader.vue'
import PlacesOfUsageGroupMappingModal from './PlacesOfUsageGroupMappingModal'
export default {
  components: {
    ConfirmModal,
    TabHeader,
    PlacesOfUsageGroupMappingModal
  },
  data () {
    return {
      isShowModal: false,
      EntityType: EntityType,
      SysRoleType: {
        ADMIN: SysRoleType.ADMIN
      },
      agents: [],
      valid: false,
      forConasi: FOR_CONASI_TARGET,
      confirmModalTitle: null,
      agentParentId: null,
      entityHeaders: [
        {
          sortable: true,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: true,
          text: 'common.code',
          value: 'code'
        },
        {
          sortable: true,
          text: 'actions.name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'userProfile.address',
          value: 'address'
        },
        {
          sortable: false,
          text: 'common.actions',
          value: 'actions',
          align: 'center'
        }
      ],
      groupSiteList: [],
      indexRetrievingAddess: -1,
      paginateData: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      groupSiteSelected: null,
      groupSiteData: {
        id: null,
        name: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
  },
  created () {
    this.getChildrenEntity()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    getModalTitle: function () {
      return 'supplier.addNewSupplier'
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.agentParentId = null
      this.groupSiteList = []
    },
    /**
     * Check is distributer
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Show modal
     */
    onShowModal: function (data) {
      this.setDefaultData()
      this.isShowModal = true
      this.groupSiteData.id = data.id
      this.groupSiteData.name = data.name
      // this.currentPage = currentPage
      // this.entity.type = type
      this.getPlacesOfUsageGroupMappingList(data.id, this.paginateData.currentPage)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefaultData()
    },
    getPlacesOfUsageGroupMappingList: function (groupSiteId, page) {
      let filter = {
        params: {
          groupSiteId: groupSiteId,
          page: page
        }
      }
      this.GET_SITE_GROUP_MAPPING(filter).then(
        function (res) {
          let data = res.data
          this.paginateData.currentPage = data.page
          this.paginateData.totalPage = data.num_pages
          this.paginateData.rowPerPage = data.per_page
          let results = data.results
          this.indexRetrievingAddess = -1
          this.groupSiteList = []
          results.forEach((e, i) => {
            let item = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: e.id,
              name: e.usage_site.name,
              code: e.usage_site.code,
              longitude: e.usage_site.longitude,
              latitude: e.usage_site.latitude,
              province: e.usage_site.province,
              district: e.usage_site.district,
              ward: e.usage_site.ward,
              street: e.usage_site.street,
              fullAddress: e.usage_site.street
            }
            this.groupSiteList.push(item)
          })
          this.indexRetrievingAddess = 0
          this.doRetrievingFullAddress()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    doRetrievingFullAddress: function () {
      if (this.indexRetrievingAddess >= 0 && this.groupSiteList && this.indexRetrievingAddess < this.groupSiteList.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.groupSiteList[this.indexRetrievingAddess].province,
            districtCode: this.groupSiteList[this.indexRetrievingAddess].district,
            wardCode: this.groupSiteList[this.indexRetrievingAddess].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.groupSiteList[this.indexRetrievingAddess].street)
            this.groupSiteList[this.indexRetrievingAddess].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddess++
            this.doRetrievingFullAddress()
          }.bind(this)
        )
      }
    },
    onShowModalAddPlacesOfUsageGroupMapping: function () {
      this.$refs.placesOfUsageGroupMappingModal.onShowModal(this.groupSiteData)
    },
    // onShowModalUpdateEntitySite: function (item) {
    //   this.$refs.placesOfUsageGroupModal.onShowModal(item)
    // },
    callbackPlacesOfUsageGroupMappingList: function () {
      this.getPlacesOfUsageGroupMappingList(this.groupSiteData.id, this.paginateData.currentPage)
    },
    onPageChange: function (page) {
      this.getPlacesOfUsageGroupMappingList(this.groupSiteData.id, page)
    },
    onShowModalDelete: function (item) {
      this.groupSiteSelected = item
      this.confirmModalTitle = this.$t('groupSites.removeSiteFromRegion', { 0: item.name })
      this.$refs.confirmModal.onShowModal()
    },
    onConfirmDelete: function () {
      this.DELETE_SITE_GROUP_MAPPING({ id: this.groupSiteSelected.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.callbackPlacesOfUsageGroupMappingList()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Get children entity
     */
    getChildrenEntity: function () {
      let filter = {
        params: {
          type: EntityType.SITE
        }
      }
      this.GET_CHILDREN_ENTITY(filter)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_SITE_GROUP_MAPPING',
      'DELETE_SITE_GROUP_MAPPING',
      'GET_CHILDREN_ENTITY',
      'GET_ADDRESS_DETAIL'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.copy-url-btn {
  min-width: 0;
}

</style>
