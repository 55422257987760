
<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="!forConasi"
      wrap>
      <v-flex md12>
        <div class="mt-4">
          <tab-header
            :tab-items="tabItems"
            @onSelectTab="onSelectTab" />
        </div>
      </v-flex>
    </v-layout>
    <div
      v-if="tabSelected === 2 && !forConasi">
      <placesOfUsageGroup />
    </div>
    <div
      v-if="tabSelected === 1">
      <v-layout
        v-if="hasViewPermission()"
        wrap>
        <v-flex md12>
          <material-card
            :title="$t('common.search')"
            color="green"
            flat
            full-width
            class="bg-white">
            <v-form>
              <v-container py-0>
                <v-layout wrap>
                  <v-flex
                    xs12
                    md6>
                    <v-text-field
                      v-model="entityFilter.text"
                      :label="$t('common.searchData')"
                      @keyup.enter="onSearch" />
                  </v-flex>
                  <!-- <v-flex
                    xs12
                    md3>
                    <v-text-field
                      v-model="entityFilter.address"
                      :label="$t('supplier.address')"
                      @keyup.enter="onSearch"
                    />
                  </v-flex> -->
                  <v-flex
                    v-if="entityType == EntityType.SUPPLIER"
                    xs12
                    md4>
                    <v-select
                      :items="entityStatusList"
                      v-model="entityFilter.status"
                      :label="$t('entity.status')"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ $t(data.item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-btn
                  style="margin-right: 0;"
                  class="pull-right"
                  color="success"
                  @click="onSearch"
                  @keyup.enter="onSearch"
                >
                  <span class="btn-label mr-3">
                    <i class="glyphicon glyphicon-search" />
                  </span>
                  <span style="text-transform: none;">{{
                    $t("common.search")
                  }}</span>
                </v-btn>
              </v-container>
            </v-form>
          </material-card>
          <material-card
            :title="$t(getEntityListName())"
            color="green"
            flat
            full-width
          >
            <template v-if="hasCreatePermissionn() && !isCurrentEntityDisabled()">
              <v-btn
                color="success"
                dark
                style="float: right;"
                @click="onShowModalAddEntity()"
              >
                {{ $t('common.add') }}
              </v-btn>
            </template>
            <div @mouseleave="$refs.tblEntityList.expanded=[]">
              <v-data-table
                ref="tblEntityList"
                :no-data-text="$t('common.noDataAvailable')"
                :headers="entityHeaders"
                :items="entityList"
                :single-select="true"
                :expand="false"
                hide-actions
              >
                <template
                  slot="headerCell"
                  slot-scope="{ header }">
                  <span
                    class="text--darken-3 font-medium"
                    v-text="$t(header.text)"
                  />
                </template>
                <template v-slot:items="props">
                  <tr
                    @click="props.expanded=!props.expanded"
                    @mouseover="props.expanded=true">
                    <td
                      v-for="(header, index) in entityHeaders"
                      :key="header.value + index"
                      :style="header.value == 'description' ? 'min-width: 150px;' : ''"
                      :class="[header.align == 'center' ? 'text-xs-center' : '', (isLocked(props.item) || props.item.isExpired) ? 'almost-expired' : '']">
                      <span
                        v-if="header.value == 'code'"
                        class="cursor-pointer"
                        style="border-bottom: 1px dashed #5cb860;">
                        <span>{{ props.item[header.value] }}</span>
                      </span>
                      <span v-else-if="header.value == 'status'">
                        <v-icon
                          v-if="isLocked(props.item)"
                          color="red">mdi-block-helper</v-icon>
                        <v-icon
                          v-else-if="props.item.isExpired"
                          color="red">mdi-close</v-icon>
                        <v-icon
                          v-else
                          color="success">mdi-check</v-icon>
                      </span>
                      <!-- <span v-else-if="header.value == 'fullAddress'">
                        <span>{{ props.item.fullAddress }}</span>
                      </span> -->
                      <span v-else-if="header.value == 'expiredDate'">
                        <div>{{ props.item[header.value] }}</div>
                        <div v-if="isLocked(props.item)">{{ $t('entity.state.inactive') }}</div>
                        <div v-else-if="props.item.isExpired">{{ $t('entity.state.expired') }}</div>
                        <div
                          v-else-if="props.item.remainDays >= 0 && props.item.remainDays < defaultAgentExpiredDays"
                          class="almost-expired">
                          {{ $t('entity.state.almostExpired', { days: props.item.remainDays + 1 }) }}
                        </div>
                      </span>
                      <span v-else>{{ props.item[header.value] }}</span>
                    </td>
                  </tr>
                </template>
                <template v-slot:expand="props">
                  <div
                    class="py-2 mx-3"
                    style="padding-left: 40px"
                    @mouseleave="props.expanded=false">

                    <div
                      v-if="props.item.fullAddress"
                      style="display: flex;">
                      <address-label
                        :longitude="props.item.longitude"
                        :latitude="props.item.latitude">
                        {{ props.item.fullAddress }}
                      </address-label>
                    </div>
                    <div style="display: flex; white-space: nowrap;">
                      <!-- View detail -->
                      <!-- <v-icon dark>mdi-account-details-outline</v-icon> -->
                      <action-item
                        first
                        class="green--text"
                        @click="showEntityConfiguration(props.item)">
                        {{ $t('evoucher.viewDetail') }}
                      </action-item>
                      <!-- Update entity -->
                      <!-- <v-icon dark>mdi-square-edit-outline</v-icon> -->
                      <action-item
                        v-if="hasUpdatePermission()"
                        class="blue--text"
                        @click="onShowModalEntityInfo(props.item)">
                        {{ $t('common.update') }}
                      </action-item>
                      <action-item
                        v-show="isAdmin()"
                        v-if="props.item.status == EntityStatus.ACTIVATED"
                        class="text-danger"
                        @click="onShowActiveEntity(props.item, false)">
                        {{ $t('entity.disabled') }}
                      </action-item>
                      <action-item
                        v-show="isAdmin()"
                        v-if="(!forConasi && entityType == EntityType.SUPPLIER) || props.item.status != EntityStatus.ACTIVATED"
                        class="text-warning"
                        @click="onShowActiveEntity(props.item, true)">
                        {{ props.item.status == EntityStatus.ACTIVATED ? $t('entity.updateActivation') : $t('entity.state.active') }}
                      </action-item>
                      <!-- <v-icon dark>mdi-delete</v-icon> -->
                      <action-item
                        v-if="hasDeleteAction(props.item)"
                        class="text-danger"
                        @click="onShowModalDeleteEntity(props.item.id, props.item.name)">
                        {{ $t('common.delete') }}
                      </action-item>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
          </material-card>
        </v-flex>
      </v-layout>
      <pagination
        :total="entityPaginate.totalPage"
        :current-page="entityPaginate.currentPage"
        :row-per-page="entityPaginate.rowPerPage"
        @onPageChange="onPageChange"
      />
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteEntity"
    />
    <!-- active entity modal -->
    <entity-activation-modal
      ref="activeEntityConfirmModal"
      @onConfirm="onEntityActivationFinish"
    />
    <addEntityModal
      ref="addEntityModal"
      @addUpdateSuccess="onAddUpdateSuccess" />
    <entityConfigurationModal ref="entityConfigurationModal" />
    <loading-bar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AddEntityModal from 'Components/AddEntityModal'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
import stringUtils from 'utils/stringUtils'
import ConfirmModal from 'Components/ConfirmModal'
import EntityStatus from 'enum/entityStatus'
import EntityConfigurationModal from 'Components/EntityConfigurationModal'
import dateUtils from 'utils/dateUtils'
import EntityActivationModal from 'Views/Entities/EntityActivationModal'
import { FOR_CONASI_TARGET, DEFAULT_AGENT_EXPIRED_DAYS } from 'utils/constants'
import ActionItem from 'Components/ActionItem.vue'
import AddressLabel from 'Components/AddressLabel.vue'
import PlacesOfUsageGroup from './PlacesOfUsageGroup'
export default {
  components: {
    addEntityModal: AddEntityModal,
    ConfirmModal,
    EntityConfigurationModal,
    EntityActivationModal,
    ActionItem,
    AddressLabel,
    PlacesOfUsageGroup
  },
  props: {
    entityType: { type: Number, default: () => 0 }
  },
  data: () => ({
    entityHeaders: [],
    entityHeaderBase: [
      {
        sortable: true,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      // {
      //   sortable: false,
      //   text: 'promotionVoucher.hasCreate',
      //   value: 'createPromotionVoucher',
      //   align: 'center'
      // },
      {
        sortable: true,
        text: 'common.code',
        value: 'code'
      },
      {
        sortable: true,
        text: 'actions.name',
        value: 'name'
      },
      {
        sortable: true,
        text: 'userProfile.phone',
        value: 'phone'
      },
      {
        sortable: true,
        text: 'userProfile.email',
        value: 'email'
      },
      {
        sortable: true,
        text: 'users.dateCreated',
        value: 'dateCreated',
        align: 'center'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.expiredDate',
        value: 'expiredDate',
        align: 'center'
      },
      {
        sortable: false,
        text: 'entity.numberOfUsers',
        value: 'quantityUser',
        align: 'center'
      },
      // {
      //   sortable: false,
      //   text: 'userProfile.address',
      //   value: 'fullAddress',
      //   width: '150px'
      // },
      {
        sortable: false,
        text: 'generalConfiguration.description',
        value: 'description'
      },
      {
        sortable: true,
        text: 'common.status',
        value: 'status',
        align: 'center'
      }
    ],
    indexRetrievingAddess: -1,
    entityList: [],
    entityPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    selected: [],
    entityFilter: {
      text: null,
      // address: null,
      status: null
    },
    entityStatusList: stringUtils.EntityStatus,
    confirmModalTitle: '',
    entitySelectedId: null,
    objectSettingKey: stringUtils.ObjectSettingKey,
    EntityType: EntityType,
    EntityStatus: {
      ACTIVATED: EntityStatus.ACTIVATED,
      INACTIVE: EntityStatus.INACTIVE
    },
    // TODO: need to check before site region feature
    // forConasi: FOR_CONASI_TARGET,
    forConasi: true,
    defaultAgentExpiredDays: DEFAULT_AGENT_EXPIRED_DAYS,
    tabItems: [
      {
        title: 'sideBar.siteManagement',
        id: 1
      },
      {
        title: 'groupSites.title',
        id: 2
      }
    ],
    tabSelected: 1
  }),
  computed: {
    ...mapGetters([
      'LIST_ENTITIES_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    entityType () {
      this.initializeView()
      this.getListEntities()
    },
    LIST_ENTITIES_DATA () {
      let res = this.LIST_ENTITIES_DATA
      // Handle paginate
      this.entityPaginate.currentPage = res.paginate.currentPage
      this.entityPaginate.totalPage = res.paginate.totalPage
      this.entityPaginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.entityList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: data[i].id,
          name: data[i].name,
          code: data[i].code,
          status: data[i].status,
          phone: data[i].phone,
          email: data[i].email,
          imageUrl: data[i].image_url,
          website: data[i].website,
          selectedButton: false,
          type: data[i].type,
          description: data[i].description,
          longitude: data[i].longitude,
          latitude: data[i].latitude,
          province: data[i].province,
          district: data[i].district,
          ward: data[i].ward,
          street: data[i].street,
          fullAddress: data[i].street,
          dateCreated: dateUtils.formatBeautyDate(data[i].date_created),
          quantityUser: data[i].quantity_user,
          // For Supplier
          createPromotionVoucher: data[i].has_create_promotion_voucher,
          expiredDate: dateUtils.formatBeautyDate(data[i].expired_date),
          isExpired: functionUtils.isEntityExpired(data[i].expired_date),
          remainDays: dateUtils.getRemainDays(data[i].expired_date),
          // For Agent
          parent: data[i].parent
        }
        this.entityList.push(entityObj)
      }
      this.indexRetrievingAddess = 0
      this.doRetrievingFullAddress()
      this.isLoading = false
    },
    // CHILDREN_ENTITY_DATA () {
    GET_CURRENT_ACCOUNT () {
      if (!this.isAdmin()) {
        this.entityHeaders = this.entityHeaders.filter((item) => {
          return item.value !== 'createPromotionVoucher'
        })
      }
    }
  },
  created () {
    this.initializeView()
    this.getListEntities()
  },
  methods: {
    isCurrentEntityDisabled: function () {
      return functionUtils.isCurrentEntityLocked() || functionUtils.isCurrentEntityExpired()
    },
    isLocked: function (entity) {
      return functionUtils.isEntityLocked(entity.status)
    },
    initializeView: function () {
      if (this.entityType === EntityType.SUPPLIER) {
        this.entityHeaders = this.entityHeaderBase.filter((item) => {
          return item.value !== 'description'
        })
      } else if (this.entityType === EntityType.AGENT) {
        this.entityHeaders = this.entityHeaderBase.filter((item) => {
          return item.value !== 'expiredDate'
        })
      } else if (this.entityType === EntityType.SITE) {
        this.entityHeaders = this.entityHeaderBase.filter((item) => {
          return item.value !== 'expiredDate' && item.value !== 'status'
        })
      } else {
        // Invalid param
        this.entityHeaders = []
      }
      if (!this.isAdmin()) {
        this.entityHeaders = this.entityHeaders.filter((item) => {
          return item.value !== 'createPromotionVoucher'
        })
      }
      if (FOR_CONASI_TARGET) {
        this.entityHeaders = this.entityHeaders.filter((item) => {
          return item.value !== 'expiredDate'
        })
      }
    },
    hasViewPermission: function () {
      if (this.isAdmin()) {
        return true
      }
      if (this.entityType === EntityType.SUPPLIER) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'view_supplier')
      }
      return true
    },
    hasUpdatePermission: function () {
      if (this.isAdmin()) {
        return true
      }
      if (this.entityType === EntityType.SUPPLIER) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'update_supplier')
      } else if (this.entityType === EntityType.AGENT) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'update_agent')
      } else if (this.entityType === EntityType.SITE) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'update_site')
      }
      return false
    },
    hasCreatePermissionn: function () {
      if (this.isAdmin()) {
        return true
      }
      if (this.entityType === EntityType.SUPPLIER) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'create_supplier')
      } else if (this.entityType === EntityType.AGENT) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'create_agent')
      } else if (this.entityType === EntityType.SITE) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'create_site')
      }
      return false
    },
    hasDeleteAction: function (entity) {
      if (this.isAdmin()) {
        return true
      }
      if (this.entityType === EntityType.SUPPLIER) {
        return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'delete_supplier')
      } else if (this.entityType === EntityType.AGENT) {
        let parentEntity = parseInt(sessionStorage.getItem('entityId'))
        return entity.parent === parentEntity && functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'delete_agent')
      } else if (this.entityType === EntityType.SITE) {
        let parentEntity = parseInt(sessionStorage.getItem('entityId'))
        return entity.parent === parentEntity && functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, 'delete_site')
      }
      return false
    },
    /**
     * Has create promotion voucher change
     */
    hasCreatePromotionVoucherChange: function (item, hasCreate) {
      let filter = {
        objectId: item.id,
        objectType: 'Entity',
        key: this.objectSettingKey.HAS_PERM_CREATE_PROMOTION_VOUCHER,
        value: hasCreate
      }
      this.CREATE_OBJECT_SETTING(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    /**
     * Add, update success
     */
    onAddUpdateSuccess: function () {
      this.getListEntities()
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * View user in entity selected
     */
    showEntityConfiguration: function (entity) {
      this.$refs.entityConfigurationModal.onShowModal(entity)
    },
    /**
     * Search
     */
    onSearch: function () {
      this.getListEntities()
    },
    onShowModalDeleteEntity: function (id, name) {
      this.confirmModalTitle = this.$t('supplier.confirmDeleteSupplierTitle', { name: name })
      this.entitySelectedId = id
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Get list entities by type
     */
    getListEntities: function () {
      let filter = {
        params: {
          page: this.entityPaginate.currentPage,
          type: this.entityType,
          entityStatus: this.entityFilter.status,
          entityAddress: null,
          searchText: this.entityFilter.text != null ? this.entityFilter.text.trim() : null
        }
      }
      this.isLoading = true
      this.indexRetrievingAddess = -1
      this.GET_LIST_ENTITIES_BY_TYPE(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getEntityListName: function () {
      if (this.entityType === EntityType.SUPPLIER) {
        return 'supplier.supplierList'
      } else if (this.entityType === EntityType.AGENT) {
        return 'agent.agentList'
      } else if (this.entityType === EntityType.SITE) {
        return 'siteManagement.list'
      }
      return ''
    },
    /**
     * Get agent status
     */
    getStatus: function (status) {
      if (status === EntityStatus.ACTIVATED) {
        return 'entity.state.active'
      } else {
        return 'entity.state.inactive'
      }
    },
    /**
     * Show modal add entity
     */
    onShowModalAddEntity: function () {
      return this.$refs.addEntityModal.onShowModal(null, null, this.entityPaginate.currentPage, this.entityType)
    },
    /**
     * Show modal edit entity
     */
    onShowModalEntityInfo: function (item) {
      let entityId = item.id
      let entityData = {
        name: item.name,
        code: item.code,
        phone: item.phone,
        email: item.email,
        website: item.website,
        imageUrl: item.imageUrl,
        description: item.description,
        longitude: item.longitude,
        latitude: item.latitude,
        province: item.province,
        district: item.district,
        ward: item.ward,
        street: item.street,
        // For both Agent and Site
        parent: item.parent
      }
      this.$refs.addEntityModal.onShowModal(entityId, entityData, this.entityPaginate.currentPage, this.entityType)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.entityPaginate.currentPage = page
      this.getListEntities()
    },
    /**
     * Delete entity
     */
    processDeleteEntity: function () {
      if (this.isAdmin()) {
        return this.DELETE_ENTITY({ id: this.entitySelectedId })
      } else {
        let filter = {
          childrenEntity: this.entitySelectedId
        }
        return this.REMOVED_ENTITY_FROM_PARENT(filter)
      }
    },
    onDeleteEntity: function () {
      this.isLoading = true
      this.processDeleteEntity().then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getListEntities()
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    onShowActiveEntity: function (entity, isActive) {
      this.$refs.activeEntityConfirmModal.onShowModal(entity, isActive)
    },
    onEntityActivationFinish: function () {
      this.getListEntities()
    },
    doRetrievingFullAddress: function () {
      if (this.indexRetrievingAddess >= 0 && this.entityList && this.indexRetrievingAddess < this.entityList.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.entityList[this.indexRetrievingAddess].province,
            districtCode: this.entityList[this.indexRetrievingAddess].district,
            wardCode: this.entityList[this.indexRetrievingAddess].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.entityList[this.indexRetrievingAddess].street)
            this.entityList[this.indexRetrievingAddess].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddess++
            this.doRetrievingFullAddress()
          }.bind(this)
        )
      }
    },
    /**
     * Select tab
     */
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
    },
    ...mapActions([
      'GET_LIST_ENTITIES_BY_TYPE',
      'REMOVED_ENTITY_FROM_PARENT',
      'DELETE_ENTITY',
      'ON_SHOW_TOAST',
      'CREATE_OBJECT_SETTING',
      'GET_ADDRESS_DETAIL'
    ])
  }
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.almost-expired {
  color: #f00;
}
</style>
