<template>
  <!-- <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;"> -->
  <div>
    <v-layout
      wrap>
      <v-flex md12>
        <material-card
          :title="$t('groupSites.title')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddPlacesOfUsageGroup()"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <div @mouseleave="$refs.tblEntityList.expanded=[]">
            <v-data-table
              ref="tblEntityList"
              :no-data-text="$t('common.noDataAvailable')"
              :headers="entityHeaders"
              :items="groupSiteList"
              :single-select="true"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }">
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr>
                  <td
                    v-for="(header, index) in entityHeaders"
                    :key="header.value + index"
                    :class="[header.align == 'center' ? 'text-xs-center' : '']"
                  >
                    <div v-if="header.value == 'actions'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="primary"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalUpdatePlacesOfUsageGroup(props.item)"
                          >
                            <v-icon dark>mdi-pencil-box-outline</v-icon>
                          </v-btn>
                        </template>
                        <span >{{ $t('groupSites.updateRegion') }}</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="info"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalPlacesOfUsageGroupMapping(props.item)"
                          >
                            <v-icon dark>mdi-account-box-multiple</v-icon>
                          </v-btn>
                        </template>
                        <!-- <span >{{ $t('entity.copyUrl') }}</span> -->
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            color="error"
                            style="color: #ffffff;"
                            v-on="on"
                            @click="onShowModalDelete(props.item)"
                          >
                            <v-icon dark>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span >{{ $t('common.delete') }}</span>
                      </v-tooltip>
                    </div>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <pagination
              :total="paginateData.totalPage"
              :current-page="paginateData.currentPage"
              :row-per-page="paginateData.rowPerPage"
              @onPageChange="onPageChange"
            />
          </div>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmDelete"
    />
    <placesOfUsageGroupModal
      ref="placesOfUsageGroupModal"
      @callbackPlacesOfUsageGroupList="callbackPlacesOfUsageGroupList"
    />
    <placesOfUsageGroupMapping
      ref="placesOfUsageGroupMapping"
    />
  <!-- </v-container> -->
  </div>
  <!-- </v-dialog> -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
import SysRoleType from 'enum/sysRoleType'
import ConfirmModal from 'Components/ConfirmModal'
import { FOR_CONASI_TARGET } from 'utils/constants'
import TabHeader from 'Components/TabHeader.vue'
import PlacesOfUsageGroupModal from './PlacesOfUsageGroupModal'
import PlacesOfUsageGroupMapping from './PlacesOfUsageGroupMapping'
export default {
  components: {
    ConfirmModal,
    TabHeader,
    PlacesOfUsageGroupModal,
    PlacesOfUsageGroupMapping
  },
  data () {
    return {
      isShowModal: false,
      textFullAddress: null,
      EntityType: EntityType,
      SysRoleType: {
        ADMIN: SysRoleType.ADMIN
      },
      agents: [],
      agentIds: [],
      valid: false,
      forConasi: FOR_CONASI_TARGET,
      confirmModalTitle: null,
      agentParentId: null,
      entityHeaders: [
        {
          sortable: true,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: true,
          text: 'actions.name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'common.actions',
          value: 'actions',
          align: 'center'
        }
      ],
      groupSiteList: [],
      paginateData: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      placesOfUsageGroupSelect: null
    }
  },
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
  },
  created () {
    this.getPlacesOfUsageGroupList(1)
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    getModalTitle: function () {
      return 'supplier.addNewSupplier'
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.agentParentId = null
    },
    /**
     * Check is distributer
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Show modal
     */
    onShowModal: function (currentPage, type) {
      this.isShowModal = true
      this.setDefaultData()
      this.currentPage = currentPage
      this.entity.type = type
      this.agentIds = []
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefaultData()
    },
    getPlacesOfUsageGroupList: function (page) {
      let filter = {
        params: {
          page: page
        }
      }
      this.GET_SITE_GROUP_LIST(filter).then(
        function (res) {
          let data = res.data
          this.paginateData.currentPage = data.page
          this.paginateData.totalPage = data.num_pages
          this.paginateData.rowPerPage = data.per_page
          let results = data.results
          this.groupSiteList = []
          results.forEach((e, i) => {
            let item = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: e.id,
              name: e.name
            }
            this.groupSiteList.push(item)
          })
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    onShowModalAddPlacesOfUsageGroup: function () {
      this.$refs.placesOfUsageGroupModal.onShowModal()
    },
    onShowModalUpdatePlacesOfUsageGroup: function (item) {
      this.$refs.placesOfUsageGroupModal.onShowModal(item)
    },
    callbackPlacesOfUsageGroupList: function () {
      this.getPlacesOfUsageGroupList(this.paginateData.currentPage)
    },
    onPageChange: function (page) {
      this.getPlacesOfUsageGroupList(page)
    },
    onShowModalDelete: function (item) {
      this.placesOfUsageGroupSelect = item
      this.confirmModalTitle = this.$t('groupSites.deleteRegionMessage')
      this.$refs.confirmModal.onShowModal()
    },
    onConfirmDelete: function () {
      this.DELETE_SITE_GROUP({ id: this.placesOfUsageGroupSelect.id }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.callbackPlacesOfUsageGroupList()
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('login.somethingWentWrong'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    onShowModalPlacesOfUsageGroupMapping: function (item) {
      this.$refs.placesOfUsageGroupMapping.onShowModal(item)
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_SITE_GROUP_LIST',
      'DELETE_SITE_GROUP'
    ])
  }
}

</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.copy-url-btn {
  min-width: 0;
}

</style>
