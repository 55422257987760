<template>
  <v-dialog
    v-model="isShowModal"
    max-width="600px">
    <v-card>
      <material-card
        :title="$t('common.notification')"
        tile
        color="green"
        full-width
      >
        <div class="mx-3 mt-3 mb-1"><span>{{ title }}</span></div>
        <v-container v-if="!forConasi && entityType==EntityType.SUPPLIER && isActivating">
          <v-layout wrap>
            <v-flex
              xs12
              md4>
              <v-select
                :items="activationDurations"
                v-model="activationSelected"
                :label="$t('activation.expirationDuration')"
              >
                <template
                  slot="selection"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
                <template
                  slot="item"
                  slot-scope="data">
                  {{ $t(data.item.text) }}
                </template>
              </v-select>
            </v-flex>
            <v-flex
              v-if="activationSelected == -1"
              xs12
              md3
              class="ml-2">
              <v-form
                ref="form"
                lazy-validation>
                <v-text-field
                  v-model="activationInput"
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  :label="$t('activation.duration')"
                  type="number"
                  style="margin-right: 5px;"
                  class="success-input required"
                />
              </v-form>
            </v-flex>
            <v-flex
              v-if="expiredDate"
              xs12
              md12>
              <span>{{ $t('activation.expirationDuration') }}: <b>{{ expiredDate }}</b></span>
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirmActivation($event)"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import ToastType from 'enum/toastType'
import EntityType from 'enum/entityType'
import validationRules from 'utils/validationRules'
import { FOR_CONASI_TARGET } from 'utils/constants'
export default {
  data: () => ({
    isShowModal: false,
    title: '',
    activationSelected: 30,
    activationInput: 30,
    activationDurations: [
      {
        text: 'activation.duration30days',
        value: 30
      },
      {
        text: 'activation.duration3months',
        value: 90
      },
      {
        text: 'activation.duration6months',
        value: 183
      },
      {
        text: 'activation.duration1year',
        value: 365
      },
      {
        text: 'activation.durationVarious',
        value: -1
      },
      {
        text: 'activation.durationUnlimited',
        value: 0
      }
    ],
    expiredDate: null,
    entityId: null,
    entityType: null,
    isActivating: false,
    EntityType: EntityType,
    forConasi: FOR_CONASI_TARGET
  }),
  methods: {
    onConfirmActivation: function (event) {
      event.preventDefault()
      if (this.isActivating) {
        if ((this.entityType === EntityType.SUPPLIER) && (this.activationSelected === -1)) {
          if (this.$refs.form.validate()) {
            if (this.activationInput > 0) {
              this.onConfirmActiveEntity(this.activationInput)
            }
          }
        } else {
          this.onConfirmActiveEntity(this.activationSelected)
        }
      } else {
        this.onDisabledEntity()
      }
    },
    onConfirmActiveEntity: function (numOfActivedDays) {
      let filter = {
        id: this.entityId,
        expired_days: (!FOR_CONASI_TARGET && (this.entityType === EntityType.SUPPLIER) && numOfActivedDays > 0) ? numOfActivedDays : null
      }
      this.ACTIVE_SUPPLIER_REGISTER(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.onConfirm()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
          this.onConfirm()
        }.bind(this)
      )
    },
    onDisabledEntity: function () {
      this.DISABLED_ENTITY({ id: this.entityId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.onConfirm()
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
          this.onConfirm()
        }.bind(this)
      )
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    onConfirm: function () {
      this.$emit('onConfirm')
      this.isShowModal = false
    },
    onShowModal: function (entity, isActivating) {
      this.entityId = entity.id
      this.entityType = entity.type
      this.isActivating = isActivating
      if (this.entityType === EntityType.SUPPLIER) {
        if (entity.remainDays > 0) {
          this.activationInput = entity.remainDays + 1
          if ([30, 90, 183, 365].includes(this.activationInput)) {
            this.activationSelected = entity.remainDays + 1
          } else {
            this.activationSelected = -1
          }
        } else {
          this.activationInput = 30
          this.activationSelected = 30
        }
        this.expiredDate = isActivating ? entity.expiredDate : ''
      } else {
        this.activationInput = -1
        this.activationSelected = -1
      }
      if (isActivating) {
        this.title = this.$t('supplier.confirmActiveSuppilerTitle', { name: entity.name })
      } else {
        this.title = this.$t('entity.areYouSureToDisableThisSupplier', { name: entity.name })
      }
      this.isShowModal = true
    },
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'ACTIVE_SUPPLIER_REGISTER',
      'DISABLED_ENTITY'
    ])
  }
}

</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
